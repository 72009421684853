import { createTheme, responsiveFontSizes } from '@mui/material'

const theme = responsiveFontSizes(
    createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1024,
                xl: 1536,
            },
        },
        typography: {
            fontFamily: '"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic", "Meiryo", "Roboto", sans-serif',
            h1: { fontSize: 32 },
            h2: { fontSize: 26 },
            h3: { fontSize: 22 },
            h4: { fontSize: 18 },
            h5: { fontSize: 16 },
            h6: { fontSize: 12 },
        },
    })
)

export default theme
